class TextScramble {
    constructor(el) {
        this.el = el
        this.chars = '!<>-_\\/[]{}—=+*^?#________'
        this.update = this.update.bind(this)
    }

    setText(newText) {
        const oldText = this.el.innerText
        const length = Math.max(oldText.length, newText.length)
        const promise = new Promise((resolve) => this.resolve = resolve)
        this.queue = []
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || ''
            const to = newText[i] || ''
            const start = Math.floor(Math.random() * 40)
            const end = start + Math.floor(Math.random() * 40)
            this.queue.push({from, to, start, end})
        }
        cancelAnimationFrame(this.frameRequest)
        this.frame = 0
        this.update()
        return promise
    }

    update() {
        let output = ''
        let complete = 0
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let {from, to, start, end, char} = this.queue[i]
            if (this.frame >= end) {
                complete++
                output += to
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.99) {
                    char = this.randomChar()
                    this.queue[i].char = char
                }
                output += `<span style="color: var(--text-3)">${char}</span>`
            } else {
                output += from
            }
        }
        this.el.innerHTML = output
        if (complete === this.queue.length) {
            this.resolve()
        } else {
            this.frameRequest = requestAnimationFrame(this.update)
            this.frame++
        }
    }

    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)]
    }
}

document.addEventListener('DOMContentLoaded', async () => {

    if (location.hash.includes('tgWebAppData')) {
        const a = document.createElement('a');
        //a.target = "_blank";
        a.href = 'https://t.me/crocs_not_bot?start=site';
        a.click();
    }

    const phrases = ['TON', 'NOT']
    const el = document.querySelector('.logo-title-change')
    const fx = new TextScramble(el)
    let counter = 0
    const next = () => {
        fx.setText(phrases[counter]).then(() => {
            setTimeout(next, 1500)
        })
        counter = (counter + 1) % phrases.length
    }
    setTimeout(next, 1500);


    const nftListResponse = await fetch("https://api.notcrocs.xyz/tonapi/nft", {
        "method": "GET",
    });
    const nftList = await nftListResponse.json();

    nftList.forEach((nftItem) => {
        const html = `
        <a href="https://getgems.io/collection/EQCnyAVaqg9qYCJaOYtT8FaU0YYcXvIGOMEkUZtoPfxr3h7S/${nftItem.friendlyAddress}" target="_blank">
            <img data-splide-lazy="${nftItem.previews[2].url}" loading="lazy" src="${nftItem.previews[2].url}" alt="${nftItem.metadata.name}">
            <h4>${nftItem.metadata.name}</h4>
        </a>`;
        const tempElement = document.createElement('li');
        tempElement.classList.add("splide__slide", "nft-list-item");
        tempElement.innerHTML = html;
        document.querySelector('.splide__list').appendChild(tempElement);
    })

    const splide = new Splide('.splide', {
        arrows: false,
        pagination: false,
        type: 'loop',
        autoWidth: true,
        autoScroll: {
            speed: 1.1,
            pauseOnHover: false,
            pauseOnFocus: false,
        }
    });
    splide.mount(window.splide.Extensions);
})
;
